<template>
  <div>
    <v-row dense class="fs-12">
      <v-col
        cols="8"
        class="fs-16 font-weight-medium primary--text d-flex align-center"
      >
        {{
          order.dc_distributor_name ||
          `Kho: ${order.warehouse_destination_code}`
        }}
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn
          small
          color="success"
          :disabled="!isAllChecked"
          @click="pickupFinish"
        >
          {{ $t("labels.finish") }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        {{ $t("labels.sku") }}:
        {{ order.total_sku }}
      </v-col>
      <v-col cols="6" class="text-right">
        {{ $t("labels.quantity_1") }}:
        {{ order.total_item }}
      </v-col>
    </v-row>

    <div class="mt-5">
      <v-simple-table
        fixed-header
        height="calc(100vh - 405px)"
        class="table-padding-2"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.goods") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 75px">
                {{ $t("labels.unit") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 75px">
                {{ $t("labels.quantity") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="width: 55px; min-width: 55px"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(item, index) in items"
              :key="`${index}_${item.id_dc_goods}`"
            >
              <td>
                <div class="fs-14 font-weight-medium">
                  {{ item.name }}
                </div>
                <div>
                  <span class="error--text">
                    {{ item.customer_goods_barcode }}
                  </span>
                </div>
              </td>
              <td>{{ item.unit }}</td>
              <td>
                <span class="fs-18 font-weight-medium">
                  {{ formatNumber(item.quantity) }}
                </span>
              </td>
              <td style="width: 55px; min-width: 55px">
                <v-btn
                  v-if="!item.is_picked"
                  x-small
                  color="warning"
                  @click="itemPicked(item, index)"
                >
                  {{ $t("labels.done_1") }}
                </v-btn>
                <v-icon v-else color="success"> mdi-check-circle </v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce, formatDateTime, formatNumber } from "@/libs/helpers";

export default {
  name: "PickupProcessing",
  components: {},
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    isLoading: false,
  }),
  computed: {
    isAllChecked() {
      const uncheck = [...this.items].filter((item) => !item.is_picked);
      return uncheck.length === 0;
    },
  },
  mounted() {
    this.getItems();
  },
  methods: {
    formatDateTime,
    formatNumber,
    getItems: debounce(function () {
      httpClient.post("/dc/v1/get-order-items", this.order).then(({ data }) => {
        this.items = [...data].map((item) => ({
          ...item,
          is_picked: false,
        }));
      });
    }, 500),
    itemPicked(item, index) {
      const items = [...this.items];
      items[index].is_picked = true;
      this.items = [...items];
    },
    async pickupFinish() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        const { data } = await httpClient.post(
          `/dc/v1/pickup-finish`,
          this.order
        );
        this.isLoading = false;
        this.$emit("onFinished", data);
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>
